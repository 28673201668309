export default {
  "blog-page-category": () => import("/opt/build/repo/layouts/blog-page-category.vue").then(m => m.default || m),
  "blog-page-default": () => import("/opt/build/repo/layouts/blog-page-default.vue").then(m => m.default || m),
  "blog-page-slug": () => import("/opt/build/repo/layouts/blog-page-slug.vue").then(m => m.default || m),
  "checkout-page-success": () => import("/opt/build/repo/layouts/checkout-page-success.vue").then(m => m.default || m),
  "default-legacy": () => import("/opt/build/repo/layouts/default-legacy.vue").then(m => m.default || m),
  "default-no-footer": () => import("/opt/build/repo/layouts/default-no-footer.vue").then(m => m.default || m),
  default: () => import("/opt/build/repo/layouts/default.vue").then(m => m.default || m),
  empty: () => import("/opt/build/repo/layouts/empty.vue").then(m => m.default || m),
  "menu-page-default": () => import("/opt/build/repo/layouts/menu-page-default.vue").then(m => m.default || m),
  "regflow-new": () => import("/opt/build/repo/layouts/regflow-new.vue").then(m => m.default || m)
}