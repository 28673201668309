import type { ProductPriceInterface } from "@/menus/product/ProductPriceInterface";

export const handle = (price: {
  price: number;
  regular_price: number;
  original_price: number;
  original_regular_price: number;
  premium_difference: number;
  original_premium_difference: number;
}): ProductPriceInterface => {
  return {
    price: price.price,
    regularPrice: price.regular_price,
    originalPrice: price.original_price,
    originalRegularPrice: price.original_regular_price,
    premiumDifference: price.premium_difference,
    originalPremiumDifference: price.original_premium_difference,
  };
};
