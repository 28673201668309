<script setup lang="ts">
const emit = defineEmits(["update:modelValue", "focus", "blur", "click"]);

const props = defineProps({
  modelValue: {
    type: [String, null] as PropType<string | null>,
    required: false,
    default: null,
  },
  id: {
    type: String,
    default: () => `input-${Math.floor(Math.random() * 10000)}`,
  },
  error: String,
  textarea: Boolean,
  inputClass: { type: String, default: "" },
  disabled: Boolean,
});

const focussed = ref(false);

const filled = computed(() => {
  return (
    props.modelValue &&
    (Number(props.modelValue) > 0 || Number(props.modelValue.length) > 0)
  );
});

const onFocus = () => {
  focussed.value = true;
  emit("focus");
};

const onBlur = () => {
  focussed.value = false;
  emit("blur");
};

const onClick = () => {
  emit("click");
};

const updateValue = (event) => {
  emit("update:modelValue", event.target.value);
};
</script>

<template>
  <div
    :class="['field', { error, filled, focussed, disabled }]"
    class="tw-flex tw-relative tw-flex-col"
  >
    <label
      :for="id"
      class="tw-absolute tw-pointer-events-none tw-top-3.5 tw-left-3.5 tw-bg-white tw-px-1.25 tw-whitespace-nowrap tw-text-left tw-text-opacity-70 tw-focus:text-opacity-100 tw-text-green-500"
      :class="{ 'tw-text-sm tw-text-gray-500 -top-2': filled || focussed }"
    >
      <slot />
    </label>

    <component
      :is="textarea ? 'textarea' : 'input'"
      v-bind="$attrs"
      :value="modelValue"
      :id="id"
      class="tw-h-10 tw-w-full tw-rounded-[7px] tw-px-4 tw-text-base tw-nl-green-100 placeholder:tw-text-nl-green-100/50 tw-shadow-nl-6"
      :class="inputClass"
      :data-cy="`input-${$attrs.name || id}`"
      :name="$attrs.name || id"
      :aria-invalid="error !== null && Number(error?.length) > 0"
      :aria-errormessage="`${id}-errormessage`"
      @input="updateValue"
      @focus="onFocus"
      @blur="onBlur"
      @click="onClick"
    />
    <div
      v-if="error"
      :id="`${id}-errormessage`"
      class="tw-font-sans tw-text-red-500 tw-text-[12px] tw-whitespace-nowrap tw-flex tw-justify-center tw-items-center tw-gap-[5px] tw-mt-1"
      aria-live="polite"
    >
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.34934 4.62L7.14934 9.08L5.89934 8.82L5.69934 4.62H7.34934ZM7.37934 10.31C7.37934 10.58 6.81934 11.14 6.54934 11.14C6.27934 11.14 5.71934 10.58 5.71934 10.31C5.71934 10.05 6.27934 9.49 6.54934 9.49C6.81934 9.49 7.37934 10.05 7.37934 10.31Z"
          fill="#DB0F00"
        />
        <circle cx="6.5" cy="7.5" r="6" stroke="#DB0F00" />
      </svg>
      <span>{{ error }}</span>
    </div>

    <div
      v-if="$slots.suffix"
      class="tw-absolute tw-bottom-1 tw-right-1"
      :data-cy="`input-${$attrs.name || id}-suffix`"
    >
      <slot name="suffix" />
    </div>
    <div
      v-if="$slots.prefix"
      class="tw-absolute tw-bottom-1 tw-left-2"
      :data-cy="`input-${$attrs.name || id}-prefix`"
    >
      <slot name="prefix" />
    </div>
  </div>
</template>

<style scoped>
.field label {
  transition-property: font-size, color, top;
  transition-duration: 300ms;
}

.field:not(.filled, .focussed) label {
  width: calc(100% - 4rem);
}

.field.focussed label,
.field.filled label {
  font-size: 0.875rem;
  /* color: rgb(110 110 110); */
}

.field.error input,
.field.error textarea {
  border: 1px solid;
  border-color: rgb(223 27 65);
  color: rgb(223 27 65);
}
</style>
