<script lang="ts" setup>
import AbandonedCart from "@/models/AbandonedCart";
import ValidationError from "@/models/ValidationError";
import { EMAIL_EXISTS_ERROR_MESSAGE } from "@/constants/error-messages";
import { SIGNUP_USED_FLAG } from "@/constants/signup";
import { ROUTE_LOGIN } from "@/constants/routes";
import * as zod from "zod";

withDefaults(
  defineProps<{
    submitText?: string;
  }>(),
  {
    submitText: "Continue",
  }
);

const validationSchema = toTypedSchema(
  zod.object({
    email: zod
      .string()
      .email("Please enter a valid email address")
      .min(1, "This field is required")
      .nullable(),
    zipCode: zod.string().min(1, "This field is required").nullable(),
  })
);
const { handleSubmit, errors } = useForm({
  validationSchema,
});
const { value: email, meta: emailMeta } = useField<string | null>("email");
const { value: zipCode, meta: zipCodeMeta } = useField<string | null>(
  "zipCode"
);
//TODO You need to create a composable for cases like this and replace it throughout the application
const focus = reactive<Record<string, boolean>>({
  email: false,
  zipCode: false,
});
const serverErrors = ref();

const emit = defineEmits(["submitted"]);

const router = useRouter();
const onboardingStore = useOnboardingStore();
const menusStore = useMenusStore();
const cartStore = useCartStore();
const loading = ref(false);
const form = ref();

const pendingBestsellerGroupId = computed(() => {
  return onboardingStore.pendingBestsellerGroupId;
});

const pendingItemsPreset = computed(() => {
  return menusStore.pendingItemsPreset;
});
const pendingCartItem = computed(() => {
  return menusStore.pendingCartItem;
});

const initialEmail = computed(() => {
  return onboardingStore.email;
});
const initialzipCode = computed(() => {
  return onboardingStore.zipCodeId;
});

const resolvedReferee = computed(() => {
  return cartStore.referee;
});

const disabledState = computed(() => {
  return (
    !emailMeta.valid ||
    !zipCodeMeta.valid ||
    errors?.value?.email ||
    (serverErrors?.value?.email && serverErrors?.value?.email[0]) ||
    errors.value.zipCode ||
    (serverErrors?.value?.zipCodeId && serverErrors?.value?.zipCodeId[0]) ||
    loading.value
  );
});

const abandonedCart = computed(() => {
  return new AbandonedCart(onboardingStore.$state);
});

function focusField(field: string) {
  focus[field] = true;
}

function blurField(field: string) {
  focus[field] = false;
}

function hasFocus(field: string) {
  return focus[field] === true;
}

onBeforeMount(() => {
  window.localStorage.setItem(SIGNUP_USED_FLAG, 1);
});

onMounted(() => {
  if (initialEmail.value) {
    email.value = initialEmail.value;
  }

  if (initialzipCode.value) {
    zipCode.value = initialzipCode.value;
  }
  if (!email.value && resolvedReferee?.value?.email?.length) {
    email.value = resolvedReferee.value.email;
  }
});
watch(initialEmail, (newValue) => {
  email.value = newValue;
});

watch(resolvedReferee, (newValue) => {
  if (newValue && !email.value && newValue.email?.length) {
    email.value = newValue.email;
  }
});

watch(initialzipCode, (newValue) => {
  zipCode.value = newValue;
});

watch(email, () => {
  if (serverErrors?.value?.email) {
    serverErrors.value.email = "";
  }
});

watch(zipCode, () => {
  if (serverErrors.value && serverErrors.value.zipCodeId) {
    serverErrors.value.zipCodeId = "";
  }
});

const captureEmail = handleSubmit(async () => {
  loading.value = true;
  try {
    await onboardingStore.UPDATE_ABANDONED_CART({
      abandonedCart: {
        email: email.value,
        zipCodeId: zipCode.value,
      },
      page: { uri: window.location.href },
    });
    if (pendingBestsellerGroupId.value) {
      await onboardingStore.FILL_CART_WITH_BESTSELLERS({
        bestsellerGroupId: pendingBestsellerGroupId.value,
        sourceUrl: window.location.href,
      });
    } else if (pendingCartItem.value && pendingCartItem.value.sku) {
      await abandonedCart.value.addProduct(
        pendingCartItem.value,
        window.location.href
      );
    } else if (pendingItemsPreset.value) {
      await abandonedCart.value.addProductsPreset(
        pendingItemsPreset.value,
        window.location.href
      );
    }
    emit("submitted");
  } catch (errors) {
    if (errors instanceof ValidationError) {
      if (
        errors.fieldErrors.email &&
        errors.fieldErrors.email[0] === EMAIL_EXISTS_ERROR_MESSAGE
      ) {
        cartStore.UPDATE_CART_STATE({ isOpen: false });
        menusStore.SHOW_CAPTURE_EMAIL_POPUP(false);
        await router.push({
          path: ROUTE_LOGIN,
          query: {
            email: email.value,
            error: EMAIL_EXISTS_ERROR_MESSAGE,
          },
        });
      } else {
        serverErrors.value = errors.fieldErrors;
      }
    }
  }
  loading.value = false;
});
</script>
<template>
  <div class="w-full tw-max-w-[308px] md:tw-max-w-[360px] tw-mx-auto">
    <form @submit.prevent="captureEmail" class="mb-16">
      <div class="tw-mb-[9px]">
        <NLInput
          @focus="focusField('email')"
          @blur="blurField('email')"
          v-model="email"
          :error="
            (!hasFocus('email') ? errors?.email : null) ||
            (serverErrors?.email && serverErrors?.email[0])
          "
          type="email"
          autocomplete="email"
          placeholder="Email"
        />
      </div>
      <div
        :class="{
          'tw-mb-[9px]':
            !errors.zipCode &&
            !serverErrors?.zipCodeId &&
            !serverErrors?.zipCodeId[0],
        }"
      >
        <NLInput
          @focus="focusField('zipCode')"
          @blur="blurField('zipCode')"
          v-model="zipCode"
          :error="
            (!hasFocus('zipCode') ? errors?.zipCode : null) ||
            (serverErrors?.zipCodeId && serverErrors?.zipCodeId[0])
          "
          type="text"
          autocomplete="postal-code"
          placeholder="Zip Code"
        />
      </div>
      <button
        type="submit"
        :disabled="disabledState"
        class="btn tw-w-full sm:tw-mt-[12px] tw-mt-[22px] focus:tw-bg-nl-violet-200 tw-text-[16px] p-2 min-h-[42px] flex items-center hover:tw-bg-nl-violet-200 tw-font-bold md:tw-font-semibold md:tw-py-[11px]"
        :class="{
          'tw-pointer-events-none tw-bg-nl-violet-100/70': disabledState,
          'tw-bg-nl-violet-100': !disabledState,
        }"
      >
        {{ submitText }}
      </button>

      <div
        class="tw-flex tw-items-center tw-justify-center tw-gap-3 tw-font-bold tw-py-4"
      >
        <CaptureIconWave />
        OR
        <CaptureIconWave />
      </div>
      <AuthGoogleLoginButton> Continue with Google </AuthGoogleLoginButton>
    </form>
    <span class="tw-block tw-text-center tw-text-sm tw-mt-[14px]"
      >Already a customer?
      <NuxtLink :to="ROUTE_LOGIN" class="tw-uppercase tw-font-bold">Log in</NuxtLink></span
    >
  </div>
</template>

<style scoped></style>
