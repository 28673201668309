import type { CartInterface } from "@/cart/CartInterface";
import type { CartItemInterface } from "@/cart/CartItemInterface";
import { handle as makeCartItem } from "@/services/api/abandonedCart/CartItemFromApiFactory";
import { handle as makePromoCode } from "@/services/api/abandonedCart/PromoCodeFromApiFactory";

export const handle = (cart: {
  id: number;
  items: { data: Array<Object> };
  promoCode: any;
  costs: {
    cart_items: number;
    order_value_discount: number;
    promo_code_cost: number;
    gift_cards_amount_applied: number;
    gift_cards_amount_available: number;
    comped_amount_applied: number;
    referral_credits_amount_applied: number;
    shipping_cost: number;
    shipping_cost_before_discount: number;
    tax_cost: number;
    tax_cost_before_discount: number;
    credits_applied_amount: number;
    total_cost: number;
  };
}): CartInterface => {
  return {
    id: cart.id,
    items: cart.items.data.map(
      (item: any): CartItemInterface => makeCartItem(item)
    ),
    promoCode: cart.promoCode ? makePromoCode(cart.promoCode.data) : null,
    cost: {
      items: cart.costs.cart_items,
      orderValueDiscount: cart.costs.order_value_discount,
      discount: cart.costs.promo_code_cost,
      gift: cart.costs.gift_cards_amount_applied,
      availableGift: cart.costs.gift_cards_amount_available,
      discountDescription: cart.promoCode ? cart.promoCode.description : "",
      compedAmountApplied: cart.costs.comped_amount_applied,
      referralCredits: cart.costs.referral_credits_amount_applied,
      shipping: cart.costs.shipping_cost,
      shippingBeforeDiscount: cart.costs.shipping_cost_before_discount,
      tax: cart.costs.tax_cost,
      taxBeforeDiscount: cart.costs.tax_cost_before_discount,
      credits: cart.costs.credits_applied_amount,
      total: cart.costs.total_cost,
    },
  };
};
