// Base implementation for cart store
import type {ReferrerInterface} from "@/user/ReferrerInterface";
import type {RefereeInterface} from "@/user/RefereeInterface";
import type {CartInterface} from "@/cart/CartInterface";
import {track as trackProductAddedToCart} from "@/cart/track/productAddedToCart";
import {removeAllItems, removeItem, updateItemQuantity,} from "~/cart/abandonedCartItems.api";
import type {CartItemInterface} from "~/cart/CartItemInterface";
import {
  applyPromoCode,
  applyReferralCode,
  getAbandonedCart,
  removePromoCode,
} from "~/services/api/abandonedCart/abandoned-cart";
import {clearAutoApplyPromoCodeCookies} from "~/services/storage";
import PromoError from "~/models/PromoError";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import type {PromoCodeInterface} from "~/cart/PromoCodeInterface";
import find from "lodash/find";

interface Costs {
  items: number;
  tax: number;
  taxBeforeDiscount: number;
  total: number;
  discount: number;
  discountDescription: string | null;
  referralCredits: number;
  shipping: number;
  shippingBeforeDiscount: number;
  orderValueDiscount: number;
  credits: number;
}

interface PromoCodeBanner {
  description?: string | null;
  id?: number | null;
  showBanner?: boolean | null;
}

interface SetCartParams {
  isOpen?: boolean;
}

interface CartState {
  id?: string | null | number;
  products: CartItemInterface[];
  costs?: Costs;
  isOpen?: boolean;
  promoCode?: PromoCodeInterface | null;
  promoCodeBanner: PromoCodeBanner | null;
  isGuideVisible: boolean;
  referralCode: string | null;
  referralCodeStatus: string | null;
  referrer: ReferrerInterface | null;
  referee: RefereeInterface | null;
}

const getInitialCosts = () => ({
  items: 0,
  tax: 0,
  taxBeforeDiscount: 0,
  total: 0,
  discount: 0,
  discountDescription: null,
  referralCredits: 0,
  shipping: 0,
  shippingBeforeDiscount: 0,
  orderValueDiscount: 0,
  credits: 0,
});
const getInitialPromo = () => ({
  code: null,
  description: null,
  tiers: [],
});

export const useCartStore = defineStore("cart", {
  state: (): CartState => ({
    id: null,
    products: [],
    costs: getInitialCosts(),
    isOpen: true,
    promoCode: getInitialPromo(),
    promoCodeBanner: null,
    isGuideVisible: false,
    referralCode: null,
    referralCodeStatus: null,
    referrer: {
      firstName: "",
      lastName: "",
    },
    referee: {
      firstName: null,
      lastName: null,
      email: null,
    },
  }),
  getters: {
    cartProductsGroupedWithCategoryName: ({ products }) => {
      const list = products
        .map((product) => {
          return {
            name: product.category.name,
            quantity: product.quantity,
            category: product.category,
          };
        })
        .reduce((result, item) => {
          const existingItem = result.find((i) => i.name == item.name);
          if (existingItem) {
            existingItem.quantity += item.quantity;
          } else {
            result.push(item);
          }
          return result;
        }, []);

      return orderBy(list, ["category.sortingWeight"], ["desc"]);
    },
    cartId: ({ id }) => id,
    isCartOpen: ({ isOpen }) => isOpen,
    cartCosts: (state) => state.costs,
    cartProducts: ({ products }) => products,
    cartProductsCount({ products }) {
      return products.reduce((count, product) => count + product.quantity, 0);
    },
    isFullyCoveredByPromotions() {
      let cost = this.cartCosts as Costs;
      return cost.total === 0 && cost.items > 0;
    },
    bannerTypeToShow() {
      const onboardingStore = useOnboardingStore();
      // When costs are loaded, we can determine the banner based on value (larger wins)
      if (this.costs && this.cartProductsCount > 0) {
        const referralCredits = Math.abs(this.costs.referralCredits);
        const discount = Math.abs(this.costs.discount);
        const promoType = (this.promoCodeBanner?.tiers && this.promoCodeBanner?.tiers[0])
            ? this.promoCodeBanner?.tiers[0].type
            : null;

        switch (true) {
          case referralCredits !== 0 && referralCredits > discount:
            return "raf";
          case discount !== 0 && discount > referralCredits:
            return "discount";
          case promoType === 'products_off':
            return "discount";
          default:
            return null;
        }
      }

      // Otherwise show only if just one banner present
      switch (true) {
        case Boolean(onboardingStore.hasPromoCodeOrPromoCodeTiers) &&
          Number(this.referrer?.firstName?.length) > 0:
          return "raf";
        case Boolean(onboardingStore.hasPromoCodeOrPromoCodeTiers):
          return "discount";
        case Number(this?.referrer?.firstName?.length) > 0:
          return "raf";
        default:
          return null;
      }
    },
  },
  actions: {
    async [ADD_PRODUCT]({
      sku,
      sourceUrl,
      qty = 1,
    }: {
      sku: string;
      sourceUrl: string;
      qty: number;
    }) {
      const onboardingStore = useOnboardingStore();
      let results;
      const existingQty =
        this.products.find((product) => product.sku === sku)?.quantity ?? 0;
      const newQuantity = existingQty + qty;

      try {
        results = await updateItemQuantity(
          String(onboardingStore.abandonedCartId),
          sku,
          newQuantity,
          sourceUrl
        );
      } catch (exception: any) {
        if (exception.message === "canceled") {
          return;
        }
        throw exception;
      }

      this.SET_CART(results.abandonedCart.cart);
      onboardingStore.UPDATE_ONBOARDING_STATE({
        isReadyToCheckout: results.abandonedCart.isReadyToCheckout,
      });
      const addToCartPixel = find(results.pixels, { name: 'AddToCart' });
      addToCartPixel && trackProductAddedToCart(addToCartPixel.id, results.difference, {
        id: Number(this.cartId),
        cost: this.cartCosts as Costs,
        promoCode: this.promoCode as PromoCodeInterface,
        items: this.cartProducts,
      });
    },
    async [REMOVE_PRODUCT]({
      sku,
      sourceUrl,
    }: {
      sku: string;
      sourceUrl: string;
    }) {
      const onboardingStore = useOnboardingStore();
      const results = await removeItem(
        String(onboardingStore?.abandonedCartId),
        sku,
        sourceUrl
      );
      this.SET_CART(results.abandonedCart.cart);
      onboardingStore.UPDATE_ONBOARDING_STATE({
        isReadyToCheckout: results.abandonedCart.isReadyToCheckout,
      });
    },
    async [EMPTY_CART]() {
      const onboardingStore = useOnboardingStore();

      const { cart, isReadyToCheckout } = await removeAllItems(
        String(onboardingStore?.abandonedCartId)
      );
      this.SET_CART(cart);
      onboardingStore.UPDATE_ONBOARDING_STATE({ isReadyToCheckout });
    },
    async [SUBTRACT_PRODUCT]({
      sku,
      sourceUrl,
    }: {
      sourceUrl: string;
      sku: string;
    }) {
      const onboardingStore = useOnboardingStore();

      let results;

      const found = this.products.find(
        (product) => product.sku === sku
      ) as CartItemInterface;

      if (found.quantity < 1) {
        return;
      }

      found.quantity--;

      try {
        results = await updateItemQuantity(
          String(onboardingStore.abandonedCartId),
          sku,
          found.quantity,
          sourceUrl
        );
      } catch (exception: any) {
        if (exception.message === "canceled") {
          return;
        }
        throw exception;
      }

      this.SET_CART(results.abandonedCart.cart);
      onboardingStore.UPDATE_ONBOARDING_STATE({
        isReadyToCheckout: results.abandonedCart.isReadyToCheckout,
      });
    },
    OPEN_CART() {
      this.UPDATE_CART_STATE({ isOpen: true });
    },
    [SHOW_CART_GUIDE]() {
      this.UPDATE_CART_STATE({ isGuideVisible: true });
    },
    [TOGGLE_CART]() {
      this.UPDATE_CART_STATE({ isOpen: !this.isOpen });
    },
    [SET_CART](params: (Partial<CartInterface> & SetCartParams) | null) {
      if (params === null) {
        this.id = null;
        this.costs = getInitialCosts();
        this.products = [];
        this.promoCode = null;
        this.isOpen = false;
      } else {
        this.id = params.id;
        this.costs = params.cost;
        this.products = params.items as CartItemInterface[];
        this.promoCode = params.promoCode;
        this.isOpen =
          typeof params.isOpen !== "undefined" ? params.isOpen : this.isOpen;
      }
    },
    [UPDATE_CART_STATE](stateUpdate: Partial<CartState>) {
      this.$patch(stateUpdate);
    },
    [CLEAR_CART]() {
      this[SET_CART]({
        id: null,
        items: [],
        cost: getInitialCosts(),
        isOpen: false,
        promoCode: getInitialPromo(),
      });
    },
    [SET_PROMO_CODE_BANNER](promoCode: PromoCodeInterface | null) {
      this.promoCodeBanner = {
        description: promoCode?.description,
        id: promoCode?.id,
        showBanner: promoCode?.showBanner,
        code: promoCode?.code,
        cartAmountMinimum: promoCode?.cartAmountMinimum,
        cartAmountMaximum: promoCode?.cartAmountMaximum,
        aggregateDollarsOffAmount: promoCode?.aggregateDollarsOffAmount,
        tiers: promoCode?.tiers?.map((regulation) => ({
          id: regulation.id,
          type: regulation.type,
          amount: regulation.amount,
          weekly_ordinal_number: regulation.weekly_ordinal_number,
          free_shipping: regulation.free_shipping,
          free_tax: regulation.free_tax,
          code: regulation.code,
        }))
      };
    },
    async [APPLY_REFERRAL_CODE]({ referralCode: code, refereeEmail }) {
      const onboardingStore = useOnboardingStore();
      try {
        const { cart, referralCode, referralCodeStatus, referrer, referee } =
          await applyReferralCode(
            onboardingStore.abandonedCartId,
            code,
            refereeEmail
          );

        this.SET_CART(cart);
        this.UPDATE_CART_STATE({
          referralCode,
          referralCodeStatus,
          referrer,
          referee,
        });
      } catch (e) {
        const { onReferralRedemptionLimit } = await getAbandonedCart(
          onboardingStore.abandonedCartId
        );
        onboardingStore.UPDATE_ONBOARDING_STATE({ onReferralRedemptionLimit });
      }
    },
    async [APPLY_REFERRAL_CODE]({ referralCode: code, refereeEmail }) {
      const onboardingStore = useOnboardingStore();
      try {
        const { cart, referralCode, referralCodeStatus, referrer, referee } =
          await applyReferralCode(
            onboardingStore.abandonedCartId,
            code,
            refereeEmail
          );
        this.SET_CART(cart);
        this.UPDATE_CART_STATE({
          referralCode,
          referralCodeStatus,
          referrer,
          referee,
        });
      } catch (e) {
        const { onReferralRedemptionLimit } = await getAbandonedCart(
          onboardingStore.abandonedCartId
        );
        onboardingStore.UPDATE_ONBOARDING_STATE({ onReferralRedemptionLimit });
      }
    },
    async [APPLY_PROMO]({ promoCode: code }) {
      const onboardingStore = useOnboardingStore();
      try {
        const { cart } = await applyPromoCode(
          Number(onboardingStore.abandonedCartId),
          code
        );
        this.SET_CART(cart);
        this.SET_PROMO_CODE_BANNER(cart.promoCode);
        onboardingStore.GET_PLANS();
      } catch (e) {
        const error = get(
          e.response.data,
          "errors.promo_code.0",
          "Promo Code is invalid."
        );
        throw new PromoError(error);
      }
    },
    async [REMOVE_PROMO]() {
      const onboardingStore = useOnboardingStore();

      const { cart } = await removePromoCode(
        Number(onboardingStore.abandonedCartId)
      );
      this.SET_CART(cart);
      this.SET_PROMO_CODE_BANNER(null);
      onboardingStore.GET_PLANS();
      clearAutoApplyPromoCodeCookies();
    },
  },
});
