// middleware/legacy-class.ts
export default defineNuxtRouteMiddleware((to, from) => {
  if (process.client) {
    const path = to.path;
    if (path.startsWith("/blog")) {
      document.body.classList.add("app-legacy");
    } else {
      document.body.classList.remove("app-legacy");
    }
  }
});
