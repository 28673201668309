import type { CartItemInterface } from "@/cart/CartItemInterface";
import { makeProductImage } from "@/services/api/abandonedCart/ProductImageApiFactory";
import { handle as makePrice } from "@/services/api/abandonedCart/PriceFromApiFactory";
import { handle as makeCategory } from "@/services/api/abandonedCart/CategoryFromApiFactory";

export const handle = (item: {
  sku: string;
  slug: string;
  description: string;
  excerpt: string;
  fiber_milligrams: number;
  superfoods_number: number;
  image: any;
  category: { data: any };
  price: { data: any };
  ingredients: string;
  is_active: boolean;
  is_composite: boolean;
  is_flavor_explorer: boolean;
  is_hidden_veggie: boolean;
  veggie_milliservings: number;
  protein_milligrams: number;
  is_premium: boolean;
  item_price: number | null;
  nutrition_image: string;
  packing_name: string;
  rating: number;
  package: string;
  name: string;
  type: string | null;
  weight: number | null;
  with: string;
  total_cost: number;
  total_cost_before_discount: number;
  quantity: number;
}): CartItemInterface => {
  return {
    sku: item.sku,
    slug: item.slug,
    description: item.description,
    excerpt: item.excerpt,
    fiberMilligrams: item.fiber_milligrams,
    superfoodsNumber: item.superfoods_number,

    image: makeProductImage(item.image),
    category: makeCategory(item.category.data),
    price: makePrice(item.price.data),

    ingredients: item.ingredients,
    isActive: item.is_active,
    isComposite: item.is_composite,
    isFlavorExplorer: item.is_flavor_explorer,
    isHiddenVeggie: item.is_hidden_veggie,

    veggieMilliservings: item.veggie_milliservings,
    proteinMilligrams: item.protein_milligrams,
    isPremium: item.is_premium,
    itemPrice: item.item_price,
    nutritionImage: item.nutrition_image,
    package: item.package,
    packingName: item.packing_name,
    rating: item.rating,
    type: item.type,
    weight: item.weight,
    with: item.with,
    name: item.name,
    totalCost: item.total_cost,
    totalCostBeforeDiscount: item.total_cost_before_discount,
    quantity: item.quantity,
  };
};
