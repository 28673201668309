import Flash from './../Flash'

export default class FlashableError extends Error {
  constructor(error) {
    super(error)
    this.errorMessage = error
  }
  dispatchFlahsNotification() {
    new Flash(this.errorMessage).error()
  }
}
