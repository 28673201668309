import validate from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import legacy_45css_45global from "/opt/build/repo/middleware/legacy-css.global.ts";
import maintenance_45global from "/opt/build/repo/middleware/maintenance.global.ts";
import router_45guard_45global from "/opt/build/repo/middleware/router-guard.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  legacy_45css_45global,
  maintenance_45global,
  router_45guard_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/build/repo/middleware/auth.ts"),
  "check-route-access": () => import("/opt/build/repo/middleware/check-route-access.ts")
}