import { default as cancellation_45processingB3ZUaoa4YyMeta } from "/opt/build/repo/pages/cancellation-processing.vue?macro=true";
import { default as contact_45usM8CpKpYLVzMeta } from "/opt/build/repo/pages/contact-us.vue?macro=true";
import { default as faqKBZt61hnJ8Meta } from "/opt/build/repo/pages/faq.vue?macro=true";
import { default as food_45nutrition1gaKazKhXwMeta } from "/opt/build/repo/pages/food-nutrition.vue?macro=true";
import { default as index_45olddlg3D96yNvMeta } from "/opt/build/repo/pages/gift-cards/index-old.vue?macro=true";
import { default as indexhddRrQjywRMeta } from "/opt/build/repo/pages/gift-cards/index.vue?macro=true";
import { default as how_45it_45worksrbbF2IDxZyMeta } from "/opt/build/repo/pages/how-it-works.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as maintenance0n2g3CNJhCMeta } from "/opt/build/repo/pages/maintenance.vue?macro=true";
import { default as indexaGzpC3UcamMeta } from "/opt/build/repo/pages/menus/index.vue?macro=true";
import { default as offer_45accepted_45processingSUm3wxm0nRMeta } from "/opt/build/repo/pages/offer-accepted-processing.vue?macro=true";
import { default as indexC9QZpjyJV7Meta } from "/opt/build/repo/pages/onboarding/checkout/index.vue?macro=true";
import { default as indexkmLN5IOreXMeta } from "/opt/build/repo/pages/onboarding/checkout/success/index.vue?macro=true";
import { default as indexPMBYaAI4WCMeta } from "/opt/build/repo/pages/onboarding/forgot-password/index.vue?macro=true";
import { default as indexr6G2DiCe9YMeta } from "/opt/build/repo/pages/onboarding/login/index.vue?macro=true";
import { default as indexoxaWjJfDqKMeta } from "/opt/build/repo/pages/onboarding/plans/index.vue?macro=true";
import { default as indexlR8R3UGDN3Meta } from "/opt/build/repo/pages/onboarding/reset-password/index.vue?macro=true";
import { default as indexbYzIXJnbznMeta } from "/opt/build/repo/pages/onboarding/signup/index.vue?macro=true";
import { default as indexN7uet1EQwYMeta } from "/opt/build/repo/pages/onboarding/verification/index.vue?macro=true";
import { default as packagingJ5clLgoBCcMeta } from "/opt/build/repo/pages/packaging.vue?macro=true";
import { default as pausing_45processingSjO0PJm9vJMeta } from "/opt/build/repo/pages/pausing-processing.vue?macro=true";
import { default as privacy_45policyQXTlhFuZ55Meta } from "/opt/build/repo/pages/privacy-policy.vue?macro=true";
import { default as reactivatejn7wlsDVa6Meta } from "/opt/build/repo/pages/reactivate.vue?macro=true";
import { default as terms9FLT7o4CI4Meta } from "/opt/build/repo/pages/terms.vue?macro=true";
import { default as what_45we_45stand_45for0XSAQ421deMeta } from "/opt/build/repo/pages/what-we-stand-for.vue?macro=true";
import { default as indexNX9AXzHMbmMeta } from "/opt/build/repo/modules/blog/pages/blog/index.vue?macro=true";
import { default as indexNJcvLSTX2rMeta } from "/opt/build/repo/modules/blog/pages/blog/search/index.vue?macro=true";
import { default as _91slug_93W5dEYIkyJGMeta } from "/opt/build/repo/modules/blog/pages/blog/[slug].vue?macro=true";
import { default as _91number_93ZmKVkUvnUYMeta } from "/opt/build/repo/modules/blog/pages/blog/page/[number].vue?macro=true";
import { default as _91number_93lh8M4fXJyoMeta } from "/opt/build/repo/modules/blog/pages/blog/category/[slug]/page/[number].vue?macro=true";
import { default as indexMi5f468nQ5Meta } from "/opt/build/repo/modules/blog/pages/blog/category/[slug]/index.vue?macro=true";
import { default as component_45stubVlEI75ehPBMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubVlEI75ehPB } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: cancellation_45processingB3ZUaoa4YyMeta?.name ?? "cancellation-processing",
    path: cancellation_45processingB3ZUaoa4YyMeta?.path ?? "/cancellation-processing",
    meta: cancellation_45processingB3ZUaoa4YyMeta || {},
    alias: cancellation_45processingB3ZUaoa4YyMeta?.alias || [],
    redirect: cancellation_45processingB3ZUaoa4YyMeta?.redirect,
    component: () => import("/opt/build/repo/pages/cancellation-processing.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/contact-us",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq",
    path: faqKBZt61hnJ8Meta?.path ?? "/faq",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: food_45nutrition1gaKazKhXwMeta?.name ?? "food-nutrition",
    path: food_45nutrition1gaKazKhXwMeta?.path ?? "/food-nutrition",
    meta: food_45nutrition1gaKazKhXwMeta || {},
    alias: food_45nutrition1gaKazKhXwMeta?.alias || [],
    redirect: food_45nutrition1gaKazKhXwMeta?.redirect,
    component: () => import("/opt/build/repo/pages/food-nutrition.vue").then(m => m.default || m)
  },
  {
    name: index_45olddlg3D96yNvMeta?.name ?? "gift-cards-index-old",
    path: index_45olddlg3D96yNvMeta?.path ?? "/gift-cards/index-old",
    meta: index_45olddlg3D96yNvMeta || {},
    alias: index_45olddlg3D96yNvMeta?.alias || [],
    redirect: index_45olddlg3D96yNvMeta?.redirect,
    component: () => import("/opt/build/repo/pages/gift-cards/index-old.vue").then(m => m.default || m)
  },
  {
    name: indexhddRrQjywRMeta?.name ?? "gift-cards",
    path: indexhddRrQjywRMeta?.path ?? "/gift-cards",
    meta: indexhddRrQjywRMeta || {},
    alias: indexhddRrQjywRMeta?.alias || [],
    redirect: indexhddRrQjywRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/gift-cards/index.vue").then(m => m.default || m)
  },
  {
    name: how_45it_45worksrbbF2IDxZyMeta?.name ?? "how-it-works",
    path: how_45it_45worksrbbF2IDxZyMeta?.path ?? "/how-it-works",
    meta: how_45it_45worksrbbF2IDxZyMeta || {},
    alias: how_45it_45worksrbbF2IDxZyMeta?.alias || [],
    redirect: how_45it_45worksrbbF2IDxZyMeta?.redirect,
    component: () => import("/opt/build/repo/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenance0n2g3CNJhCMeta?.name ?? "maintenance",
    path: maintenance0n2g3CNJhCMeta?.path ?? "/maintenance",
    meta: maintenance0n2g3CNJhCMeta || {},
    alias: maintenance0n2g3CNJhCMeta?.alias || [],
    redirect: maintenance0n2g3CNJhCMeta?.redirect,
    component: () => import("/opt/build/repo/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: indexaGzpC3UcamMeta?.name ?? "menus",
    path: indexaGzpC3UcamMeta?.path ?? "/menus",
    meta: indexaGzpC3UcamMeta || {},
    alias: indexaGzpC3UcamMeta?.alias || [],
    redirect: indexaGzpC3UcamMeta?.redirect,
    component: () => import("/opt/build/repo/pages/menus/index.vue").then(m => m.default || m)
  },
  {
    name: offer_45accepted_45processingSUm3wxm0nRMeta?.name ?? "offer-accepted-processing",
    path: offer_45accepted_45processingSUm3wxm0nRMeta?.path ?? "/offer-accepted-processing",
    meta: offer_45accepted_45processingSUm3wxm0nRMeta || {},
    alias: offer_45accepted_45processingSUm3wxm0nRMeta?.alias || [],
    redirect: offer_45accepted_45processingSUm3wxm0nRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/offer-accepted-processing.vue").then(m => m.default || m)
  },
  {
    name: indexC9QZpjyJV7Meta?.name ?? "onboarding-checkout",
    path: indexC9QZpjyJV7Meta?.path ?? "/onboarding/checkout",
    meta: indexC9QZpjyJV7Meta || {},
    alias: indexC9QZpjyJV7Meta?.alias || [],
    redirect: indexC9QZpjyJV7Meta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: indexkmLN5IOreXMeta?.name ?? "onboarding-checkout-success",
    path: indexkmLN5IOreXMeta?.path ?? "/onboarding/checkout/success",
    meta: indexkmLN5IOreXMeta || {},
    alias: indexkmLN5IOreXMeta?.alias || [],
    redirect: indexkmLN5IOreXMeta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/checkout/success/index.vue").then(m => m.default || m)
  },
  {
    name: indexPMBYaAI4WCMeta?.name ?? "onboarding-forgot-password",
    path: indexPMBYaAI4WCMeta?.path ?? "/onboarding/forgot-password",
    meta: indexPMBYaAI4WCMeta || {},
    alias: indexPMBYaAI4WCMeta?.alias || [],
    redirect: indexPMBYaAI4WCMeta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexr6G2DiCe9YMeta?.name ?? "onboarding-login",
    path: indexr6G2DiCe9YMeta?.path ?? "/onboarding/login",
    meta: indexr6G2DiCe9YMeta || {},
    alias: indexr6G2DiCe9YMeta?.alias || [],
    redirect: indexr6G2DiCe9YMeta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexoxaWjJfDqKMeta?.name ?? "onboarding-plans",
    path: indexoxaWjJfDqKMeta?.path ?? "/onboarding/plans",
    meta: indexoxaWjJfDqKMeta || {},
    alias: indexoxaWjJfDqKMeta?.alias || [],
    redirect: indexoxaWjJfDqKMeta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/plans/index.vue").then(m => m.default || m)
  },
  {
    name: indexlR8R3UGDN3Meta?.name ?? "onboarding-reset-password",
    path: indexlR8R3UGDN3Meta?.path ?? "/onboarding/reset-password",
    meta: indexlR8R3UGDN3Meta || {},
    alias: indexlR8R3UGDN3Meta?.alias || [],
    redirect: indexlR8R3UGDN3Meta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexbYzIXJnbznMeta?.name ?? "onboarding-signup",
    path: indexbYzIXJnbznMeta?.path ?? "/onboarding/signup",
    meta: indexbYzIXJnbznMeta || {},
    alias: indexbYzIXJnbznMeta?.alias || [],
    redirect: indexbYzIXJnbznMeta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexN7uet1EQwYMeta?.name ?? "onboarding-verification",
    path: indexN7uet1EQwYMeta?.path ?? "/onboarding/verification",
    meta: indexN7uet1EQwYMeta || {},
    alias: indexN7uet1EQwYMeta?.alias || [],
    redirect: indexN7uet1EQwYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/onboarding/verification/index.vue").then(m => m.default || m)
  },
  {
    name: packagingJ5clLgoBCcMeta?.name ?? "packaging",
    path: packagingJ5clLgoBCcMeta?.path ?? "/packaging",
    meta: packagingJ5clLgoBCcMeta || {},
    alias: packagingJ5clLgoBCcMeta?.alias || [],
    redirect: packagingJ5clLgoBCcMeta?.redirect,
    component: () => import("/opt/build/repo/pages/packaging.vue").then(m => m.default || m)
  },
  {
    name: pausing_45processingSjO0PJm9vJMeta?.name ?? "pausing-processing",
    path: pausing_45processingSjO0PJm9vJMeta?.path ?? "/pausing-processing",
    meta: pausing_45processingSjO0PJm9vJMeta || {},
    alias: pausing_45processingSjO0PJm9vJMeta?.alias || [],
    redirect: pausing_45processingSjO0PJm9vJMeta?.redirect,
    component: () => import("/opt/build/repo/pages/pausing-processing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyQXTlhFuZ55Meta?.name ?? "privacy-policy",
    path: privacy_45policyQXTlhFuZ55Meta?.path ?? "/privacy-policy",
    meta: privacy_45policyQXTlhFuZ55Meta || {},
    alias: privacy_45policyQXTlhFuZ55Meta?.alias || [],
    redirect: privacy_45policyQXTlhFuZ55Meta?.redirect,
    component: () => import("/opt/build/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: reactivatejn7wlsDVa6Meta?.name ?? "reactivate",
    path: reactivatejn7wlsDVa6Meta?.path ?? "/reactivate",
    meta: reactivatejn7wlsDVa6Meta || {},
    alias: reactivatejn7wlsDVa6Meta?.alias || [],
    redirect: reactivatejn7wlsDVa6Meta?.redirect,
    component: () => import("/opt/build/repo/pages/reactivate.vue").then(m => m.default || m)
  },
  {
    name: terms9FLT7o4CI4Meta?.name ?? "terms",
    path: terms9FLT7o4CI4Meta?.path ?? "/terms",
    meta: terms9FLT7o4CI4Meta || {},
    alias: terms9FLT7o4CI4Meta?.alias || [],
    redirect: terms9FLT7o4CI4Meta?.redirect,
    component: () => import("/opt/build/repo/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: what_45we_45stand_45for0XSAQ421deMeta?.name ?? "what-we-stand-for",
    path: what_45we_45stand_45for0XSAQ421deMeta?.path ?? "/what-we-stand-for",
    meta: what_45we_45stand_45for0XSAQ421deMeta || {},
    alias: what_45we_45stand_45for0XSAQ421deMeta?.alias || [],
    redirect: what_45we_45stand_45for0XSAQ421deMeta?.redirect,
    component: () => import("/opt/build/repo/pages/what-we-stand-for.vue").then(m => m.default || m)
  },
  {
    name: indexNX9AXzHMbmMeta?.name ?? "blog",
    path: indexNX9AXzHMbmMeta?.path ?? "/blog/",
    meta: indexNX9AXzHMbmMeta || {},
    alias: indexNX9AXzHMbmMeta?.alias || [],
    redirect: indexNX9AXzHMbmMeta?.redirect,
    component: () => import("/opt/build/repo/modules/blog/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexNJcvLSTX2rMeta?.name ?? "blog-search",
    path: indexNJcvLSTX2rMeta?.path ?? "/blog/search/",
    meta: indexNJcvLSTX2rMeta || {},
    alias: indexNJcvLSTX2rMeta?.alias || [],
    redirect: indexNJcvLSTX2rMeta?.redirect,
    component: () => import("/opt/build/repo/modules/blog/pages/blog/search/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93W5dEYIkyJGMeta?.name ?? "blog-post",
    path: _91slug_93W5dEYIkyJGMeta?.path ?? "/blog/:slug/",
    meta: _91slug_93W5dEYIkyJGMeta || {},
    alias: _91slug_93W5dEYIkyJGMeta?.alias || [],
    redirect: _91slug_93W5dEYIkyJGMeta?.redirect,
    component: () => import("/opt/build/repo/modules/blog/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91number_93ZmKVkUvnUYMeta?.name ?? "blog-page",
    path: _91number_93ZmKVkUvnUYMeta?.path ?? "/blog/page/:number",
    meta: _91number_93ZmKVkUvnUYMeta || {},
    alias: _91number_93ZmKVkUvnUYMeta?.alias || [],
    redirect: _91number_93ZmKVkUvnUYMeta?.redirect,
    component: () => import("/opt/build/repo/modules/blog/pages/blog/page/[number].vue").then(m => m.default || m)
  },
  {
    name: _91number_93lh8M4fXJyoMeta?.name ?? "blog-category-page-number",
    path: _91number_93lh8M4fXJyoMeta?.path ?? "/blog/category/:slug/page/:number/",
    meta: _91number_93lh8M4fXJyoMeta || {},
    alias: _91number_93lh8M4fXJyoMeta?.alias || [],
    redirect: _91number_93lh8M4fXJyoMeta?.redirect,
    component: () => import("/opt/build/repo/modules/blog/pages/blog/category/[slug]/page/[number].vue").then(m => m.default || m)
  },
  {
    name: indexMi5f468nQ5Meta?.name ?? "blog-category-page",
    path: indexMi5f468nQ5Meta?.path ?? "/blog/category/:slug/",
    meta: indexMi5f468nQ5Meta || {},
    alias: indexMi5f468nQ5Meta?.alias || [],
    redirect: indexMi5f468nQ5Meta?.redirect,
    component: () => import("/opt/build/repo/modules/blog/pages/blog/category/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubVlEI75ehPBMeta?.name ?? undefined,
    path: component_45stubVlEI75ehPBMeta?.path ?? "/onboarding",
    meta: component_45stubVlEI75ehPBMeta || {},
    alias: component_45stubVlEI75ehPBMeta?.alias || [],
    redirect: component_45stubVlEI75ehPBMeta?.redirect,
    component: component_45stubVlEI75ehPB
  }
]