import {navigateTo} from "#app";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const config = useRuntimeConfig();

    if (from.path === to.path || to.path === "/maintenance") return;

    if (process.env.NUXT_MAINTENANCE_MODE === 'true') {
        return navigateTo('/maintenance');
    }

    return;
});
