import { findGetParameter, findBooleanGetParameter } from "@/utils/helpers";
import { getUrlReferralCode, getUrlRefereeEmail } from "@/services/url";
import ValidationError from "@/models/ValidationError";
import { saveAutoApplyPromoCodeCookies } from "@/services/storage";
import PromoError from "@/models/PromoError";

export default class UrlParametersHandler {
  async handleCustomerFromUrl() {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    let shouldRedirectTo = null;

    const redirectWhitelist = ["/onboarding/reset-password/"];
    // NL-6501: Replace query params to exclude email so Meta Pixel doesn't log it
    let query = Object.fromEntries(params.entries());

    if (!redirectWhitelist.includes(window.location.pathname) && query.email) {
      delete query.email;
      const newQueryString = (new URLSearchParams(query)).toString();
      shouldRedirectTo =
        window.location.pathname + (newQueryString ? "?" + newQueryString : "");
    }

    const zipCodeId = params.get("zip");
    const authStore = useAuthStore();
    const onboardingStore = useOnboardingStore();
    if (email && authStore.isLoggedIn === false) {
      try {
        await onboardingStore.UPDATE_ABANDONED_CART({
          abandonedCart: { email, zipCodeId },
          page: { uri: window.location.href },
        });
      } catch (error) {
        if (!error instanceof ValidationError) {
          throw error;
        }
      }
    }

    if (shouldRedirectTo) {
      window.location.href = shouldRedirectTo;
    }
  }

  async handlePlanFromUrl() {
    const onboardingStore = useOnboardingStore();
    const cartStore = useCartStore();
    const planSlug = findGetParameter("plan");
    if (!planSlug) {
      return;
    }

    let plans = onboardingStore.plans;
    if (plans.length === 0) {
      await onboardingStore.GET_PLANS();
    }

    plans = onboardingStore.plans;
    if (plans.length === 0) {
      return;
    }

    let plan = plans.find((i) => i.slug === planSlug);
    if (!plan) {
      plan = plans.find((plan) =>
        plan.tag ? plan.tag.toLowerCase().indexOf("popular") > -1 : false
      );
    }

    if (!plan) {
      return;
    }

    if (
      onboardingStore.selectedPlan &&
      plan.threshold < onboardingStore.selectedPlan.threshold
    ) {
      await cartStore.EMPTY_CART();
    }

    await onboardingStore.UPDATE_PLAN({
      planId: plan.id,
      sourceUrl: window.location.href,
    });
  }

  async handlePromoCode(pc = null, pc_overwrite = null) {
    const onboardingStore = useOnboardingStore();
    const cartStore = useCartStore();

    const promoCode = pc ?? findGetParameter("pc");
    const overwrite = pc_overwrite ?? findBooleanGetParameter("pc_overwrite");
    if (!promoCode) return;

    if (onboardingStore.abandonedCartId) {
      try {
        await cartStore.APPLY_PROMO({ promoCode });
      } catch (error) {
        if (error instanceof PromoError) {
          return;
        }
        throw error;
      }
    }

    saveAutoApplyPromoCodeCookies(promoCode, overwrite);
  }

  handleReferralCode() {
    const onboardingStore = useOnboardingStore();
    const cartStore = useCartStore();
    const referralCode = getUrlReferralCode();
    const refereeEmail = getUrlRefereeEmail() ?? null;
    if (referralCode && onboardingStore.abandonedCartId) {
      cartStore.APPLY_REFERRAL_CODE({ referralCode, refereeEmail });
    }
  }
}
