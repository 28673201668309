import { URL_APP_SETTINGS } from '../../constants/urls'
import request from '../request'

export const getAppSettings = async () => {
  try {
    const response = await request.get(URL_APP_SETTINGS)
    return response.data.data
  } catch (e) {
    return null
  }
}
