import get from "lodash/get";
import type { AbandonedCartInterface } from "@/cart/AbandonedCartInterface";
import { handle as parseCart } from "@/services/api/abandonedCart/CartFromApiFactory";
import type { RefereeInterface } from "@/user/RefereeInterface";

export const handle = (abandoned: {
  id: number;
  email: string;
  plan_id: number;
  filters: object;
  cart: { data: any };
  profile: { data: { id: number; id_me_profile: { data: Object } } };
  partnership: { data: object | null };
  referrer: object | null;
  referee: { data: RefereeInterface } | null;
  first_name: string;
  last_name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  zip_code_id: string;
  state_id: string;
  phone: string;
  delivery_instructions: string;
  address_type: string;
  referral_code: string;
  referral_code_status: string;
  price_variance_group_id: number;
  is_ready_to_checkout: boolean;
  is_ready_to_complete_checkout: boolean;
  on_referral_redemption_limit: boolean;
  receives_sms_updates: boolean;
}): AbandonedCartInterface => {
  return {
    abandonedCartId: abandoned.id,
    email: abandoned.email,
    profileId: abandoned.profile.data.id,
    planId: abandoned.plan_id,
    filters: {
      indicators: get(abandoned.filters, "indicators", []),
      tags: get(abandoned.filters, "tags", []),
    },
    referralCode: abandoned.referral_code,
    referralCodeStatus: abandoned.referral_code_status,
    referrer: {
      firstName: get(abandoned.referrer, "first_name", ""),
      lastName: get(abandoned.referrer, "last_name", ""),
    },
    referee: {
      firstName: get(abandoned.referee?.data, "first_name", ""),
      lastName: get(abandoned.referee?.data, "last_name", ""),
      email: get(abandoned.referee?.data, "email", ""),
    },
    firstName: abandoned.first_name,
    lastName: abandoned.last_name,
    addressLine1: abandoned.address_line_1,
    addressLine2: abandoned.address_line_2,
    city: abandoned.city,
    phone: abandoned.phone,
    stateId: abandoned.state_id,
    zipCodeId: abandoned.zip_code_id,
    deliveryInstructions: abandoned.delivery_instructions,
    cart: parseCart(abandoned.cart.data),
    partnership: {
      code: get(abandoned.partnership.data, "code", null),
      description: get(abandoned.partnership.data, "description", null),
      maxOrderValue: get(abandoned.partnership.data, "max_order_value", null),
    },
    priceVarianceGroupId: abandoned.price_variance_group_id,
    isReadyToCheckout: abandoned.is_ready_to_checkout,
    isReadyToCompleteCheckout: abandoned.is_ready_to_complete_checkout,
    onReferralRedemptionLimit: abandoned.on_referral_redemption_limit,
    receivesSmsUpdates: abandoned.receives_sms_updates,
    password: null,
  };
};

export default { handle };
