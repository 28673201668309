<template>
  <NuxtLayout>
    <NuxtPage />
    <CaptureEmailPopup />
  </NuxtLayout>
  <FlashMessage />
  <client-only>
    <BeamNonProfitTracker />
  </client-only>
</template>

<script lang="ts" setup>
import UrlParametersHandler from "@/models/UrlParametersHandler";
import BeamNonProfitTracker from "~/components/ExternalServices/Beam/BeamNonProfitTracker.vue";
const UrlHandler = new UrlParametersHandler();
const authStore = useAuthStore();
const onboardingStore = useOnboardingStore();
const experimentStore = useExperimentStore();
const appSettingsStore = useAppSettingsStore();
const cartStore = useCartStore();
const { $onGlobalEvent, $offGlobalEvent } = useNuxtApp();

useClassChangeObserver();
useViewportHeight();
useScreen({
  desktop: "(min-width: 1024px)",
  mobile: "(max-width: 768px)",
  tablet: "(max-width: 1024px) and (min-width: 768px)",
});

onMounted(async () => {
  await authStore[GET_USER]();

  if (authStore.isLoggedIn === false) {
    await onboardingStore.RESTORE_ABANDONED_CART();
    if (!onboardingStore.abandonedCartId) {
      await onboardingStore.CREATE_ABANDONED_CART();
    }
  }
  await experimentStore.getExperiments();

  if (authStore.isLoggedIn === true && onboardingStore.abandonedCartId) {
    cartStore.CLEAR_CART();
    onboardingStore.CLEAR_ABANDONED_CART();
  }

  await UrlHandler.handleCustomerFromUrl();
  await UrlHandler.handlePromoCode();

  await onboardingStore.GET_PLANS();

  await UrlHandler.handlePlanFromUrl();

  UrlHandler.handleReferralCode();

  appSettingsStore.GET_APP_SETTINGS();

  // Set up a global error handler for unhandled promise rejections
  window.addEventListener("unhandledrejection", (event) => {
    if (event.reason && event.reason.name === "NavigationDuplicated") {
      console.info(
        `Duplicated route detected from ${event.reason?.from?.fullPath} to ${event.reason?.to?.fullPath}.`
      );
      event.preventDefault();
    }
  });

  $onGlobalEvent("applyPromoCode", (event: CustomEvent) => {
    UrlHandler.handlePromoCode(event.detail.pc, event.detail.pc_overwrite);
  });
});

onUnmounted(() => {
  // removes event listener
  $offGlobalEvent("applyPromoCode");
});
</script>
