<script lang="ts" setup>
const show = ref(false);
const type = ref(null);
const message = ref(null);
const duration = ref(3000);

watch(
  () => show.value,
  (value) => {
    if (value === true) {
      setTimeout(() => (show.value = false), duration.value);
    }
  }
);

onMounted(() => {
  document.addEventListener("flashMessage", (event) => {
    type.value = event.detail.type;
    message.value = event.detail.message;
    show.value = true;
    duration.value = event.detail.duration ?? 3000;
  });
});
</script>

<template>
  <div
    v-if="show"
    class="tw-pointer-events-none tw-fixed tw-top-[var(--om-floating-height)] tw-inset-x-0 tw-bottom-0 tw-z-50 tw-flex tw-items-start tw-px-[16px] tw-py-[24px] tw-justify-end"
  >
    <div
      class="tw-pointer-events-auto tw-rounded-lg tw-shadow-lg tw-self-end tw-border-3"
      :class="[
        type == 'error'
          ? 'tw-bg-red-50 tw-border-red-600'
          : 'tw-bg-[#8EDD71] tw-border-[#004B2C]',
      ]"
    >
      <div class="tw-shadow-xs tw-overflow-hidden tw-rounded-lg">
        <div class="tw-p-2.5">
          <div class="tw-flex tw-items-center tw-gap-5 tw-max-h-4">
            <div class="tw-flex-1 tw-pt-px">
              <p
                :class="[
                  type == 'error' ? '  tw-text-red-600' : 'tw-text-[#004B2C]',
                ]"
                class="font-agenda tw-text-[18px] tw-font-bold tw-leading-[100%]"
              >
                {{ message }}
              </p>
            </div>
            <button
              @click="show = false"
              :class="[
                type == 'error' ? ' tw-text-red-600' : 'tw-text-[#004B2C]',
              ]"
              class="tw-flex tw-items-center tw-justify-center tw-transition tw-duration-150 tw-ease-in-out focus:tw-text-gray-500 focus:tw-outline-none"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.355591"
                  y="11.6564"
                  width="15.794"
                  height="2.32264"
                  rx="1.16132"
                  transform="rotate(-45 0.355591 11.6564)"
                  fill="currentColor"
                />
                <rect
                  x="2.03464"
                  y="0.637512"
                  width="15.794"
                  height="2.32264"
                  rx="1.16132"
                  transform="rotate(45 2.03464 0.637512)"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped></style>
