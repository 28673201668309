import {useRouter} from 'vue-router';
import eventsManager from '~/services/events/eventsManager';
import DomainEvents from "~/models/DomainEvents";
import {restoreAbandonedCartId} from "~/services/storage";
import {randomUUID} from "uncrypto";

export default defineNuxtPlugin(() => {
  const router = useRouter();
    const domainEvents = ref(new DomainEvents());


    router.afterEach((to) => {
        let abandonedCartId = null;
        if (!process.client || !(abandonedCartId = restoreAbandonedCartId())) {
            return;
        }
        const eventId = randomUUID();

        eventsManager.push("pageViewed", {
            urlFullPath: to.fullPath,
            conversion: {
                eventId: eventId
            }
        });
        domainEvents.value.push("viewed-page", {
            url: window.location.origin + to.fullPath,
            name: to.name,
            abandonedCartId: abandonedCartId,
            parameters: {
                eventId: eventId
            }
        });
  });

});
