import eventsManager from "@/services/events/eventsManager";

const track = async (eventId: string, planId: number) => {
  await eventsManager.push("planChanged", {
    env: import.meta.env.VITE_NODE_ENV,
    planId,
    planChangedEventId: eventId,
  });
};

export { track };
