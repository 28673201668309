import type {
  ProductImageApiResponseInterface,
  ProductImageInterface,
} from "@/menus/product/ProductImageInterface";

export const makeProductImage = (
  image: ProductImageApiResponseInterface
): ProductImageInterface => ({
  cartDesktop: image["cart-desktop"],
  cartMobile: image["cart-mobile"],
  landingDesktop: image["landing-desktop"],
  mealCardDesktop: image["meal-card-desktop"],
  mealCardMobile: image["meal-card-mobile"],
});

export const makeProductImages = (
  images: { uris: ProductImageInterface }[]
) => {
  return images.map((image) =>
    makeProductImage(image.uris as unknown as ProductImageApiResponseInterface)
  );
};
