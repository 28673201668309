export const ROUTE_MAIN = "/";
export const ROUTE_MENUS = "/menus/";
export const ROUTE_MENU = "/menus/{{slug}}/";
export const ROUTE_TERMS = "/terms/";
export const ROUTE_PRIVACY = "/privacy-policy/";
export const ROUTE_NOT_FOUND = "/not-found/";
export const ROUTE_GIFT_CARDS = "/gift-cards/";
export const ROUTE_ONBOARDING = "/onboarding/";
export const ROUTE_LOGIN = "/onboarding/login/";
export const ROUTE_VERIFICATION = '/onboarding/verification/:verificationId/'
export const ROUTE_SIGNUP = "/onboarding/signup/";
export const ROUTE_REACTIVATE = "/reactivate/";
export const ROUTE_FORGOT_PASSWORD = "/onboarding/forgot-password/";
export const ROUTE_RESET_PASSWORD = "/onboarding/reset-password/";
export const ROUTE_PLANS = "/onboarding/plans/";
export const ROUTE_CHECKOUT = "/onboarding/checkout/";
export const ROUTE_SUBSCRIBE_SUCCESS = "/onboarding/checkout/success/";
export const ROUTE_PARTNER_SUBSCRIBE_SUCCESS =
  "/onboarding/checkout/success/partner-subscribe/";
export const ROUTE_BRIGHTBACK_CANCELLATION_PROCESSING =
  "/cancellation-processing/";
export const ROUTE_BRIGHTBACK_PAUSING_PROCESSING = "/pausing-processing/";
export const ROUTE_BRIGHTBACK_SUBSCRIPTION_OFFER_ACCEPTED =
  "/offer-accepted-processing/";

export const ROUTE_NAMES = [
  "menus",
  "onboarding-checkout",
  "onboarding-plans",
  "onboarding-checkout-success",
  "onboarding-checkout-processing",
  "reactivate",
  "onboarding-signup",
  "blog",
  "onboarding-login",
] as const;

export const MAPPED_ROUTE_NAMES: Record<string, RouteName> = {
  MENUS: "menus",
  CHECKOUT: "onboarding-checkout",
  PLANS: "onboarding-plans",
  SUCCESS: "onboarding-checkout-success",
  REACTIVATE: "reactivate",
  ONBOARDING_PLANS: "onboarding-plans",
  SIGNUP: "onboarding-signup",
  PROCESSING: "onboarding-checkout-processing",
  BLOG: "blog",
  LOGIN: "onboarding-login",
};

export type RouteName = (typeof ROUTE_NAMES)[number];
