import mitt from "mitt";

export default defineNuxtPlugin((nuxtApp) => {
  const emitter = mitt();

  const emitCustomEvent = (
    eventName: string,
    detail: CustomEvent["detail"]
  ): void => {
    emitter.emit(eventName, { detail });
  };

  const applyPromoCode = (pc: string, pc_overwrite: boolean): void => {
    emitCustomEvent("applyPromoCode", { pc, pc_overwrite });
  };

  if (process.client) {
    window.applyPromoCode = applyPromoCode;
  }

  return {
    provide: {
      emitGlobalEvent: emitter.emit, // Will emit an event
      onGlobalEvent: emitter.on, // Will register a listener for an event
      offGlobalEvent: emitter.off, // Will remove a listener for an event
    },
  };
});
