import axios, { type AxiosInstance } from "axios";
import { URL_API_BASE } from "@/constants/urls";
import AxiosResponseCommonCodeExceptionHandler from "@/services/AxiosResponseCommonCodeExceptionHandler";
import ValidationError from "~/models/ValidationError";

export const request: AxiosInstance = axios.create({
  baseURL: URL_API_BASE,
  withCredentials: true,
});

request.interceptors.response.use(
  (res) => res,
  AxiosResponseCommonCodeExceptionHandler,
  (error) => {
    if (error.response) {
      if (error.response.status === 422) {
        const errors = error.response.data.errors;
        if (errors) {
          return Promise.reject(new ValidationError(errors));
        } else {
          return Promise.reject(
            new Error(
              error.response.data.message || "Validation error occurred"
            )
          );
        }
      }
    } else if (error.request) {
      return Promise.reject(new Error("No response received from server"));
    } else {
      return Promise.reject(new Error("Error setting up the request"));
    }
    return Promise.reject(error);
  }
);

export const directRequest = axios.create();

export function controller(): AbortController {
  return new AbortController();
}

export default request;
