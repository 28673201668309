<script setup lang="ts">
import { GOOGLE_AUTH } from "@/constants/urls";

const onboardingStore = useOnboardingStore();
const googleAuth = ref(GOOGLE_AUTH);

const abandonedCartId = computed(() => onboardingStore.abandonedCartId);
</script>
<template>
  <a
    :href="`${googleAuth}?abandoned_cart_id=${abandonedCartId}`"
    class="tw-mb-3 tw-rounded-[7px] tw-bg-white hover:tw-bg-white/80 tw-nl-bg-green-100 tw-flex tw-h-10 tw-items-center tw-justify-center tw-w-full tw-font-bold tw-text-base tw-gap-2 tw-shadow-nl-6"
  >
    <img class="tw-w-5" src="/assets/images/ico-google.png" alt="google" />
    <span> <slot></slot></span>
  </a>
</template>
<style scoped></style>
