<template>

</template>

<script setup>
import {updateBeamNonProfit} from "@/services/api/onboarding";
import {events} from "@beamimpact/web-sdk/dist/utils";

const onboardingStore = useOnboardingStore();

const listenToBeamChanges = () => {
  window.addEventListener(events.BeamNonprofitSelectEvent.eventName, async (event) => {
    const {selectedNonprofitId, nonprofitName} = event.detail;

    updateBeamNonProfit(selectedNonprofitId, nonprofitName, onboardingStore.abandonedCartId)
  });
}

onMounted(() => {
  listenToBeamChanges()
});

</script>
