import { onMounted, onUnmounted } from "vue";

export default function useClassChangeObserver() {
  let observer: MutationObserver;

  const updateElementsStyle = (selector, styleProperty, value) => {
    document.querySelectorAll(selector).forEach((item) => {
      item.style[styleProperty] = value;
    });
  };

  const setupObserver = () => {
    const htmlElement = document.querySelector("html");
    if (!htmlElement) {
      console.error("HTML element not found");
      return;
    }

    observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.target.className === "om-position-floating-top") {
          const banner = document.getElementsByClassName(
            "om-position-floating-top"
          )[0];
          const bannerHeight = parseInt(banner.style.paddingTop, 10);
          const setHeightForFullHeightElements =
            window.innerHeight - bannerHeight;

          updateElementsStyle(
            "#app > .h-full.min-h-screen",
            "height",
            `${setHeightForFullHeightElements}px`
          );
          updateElementsStyle(
            "#content .sticky.top-0",
            "top",
            `${bannerHeight}px`
          );
          if (window.innerWidth < 1024) {
            updateElementsStyle(".fixed.inset-0", "top", `${bannerHeight}px`);
          }

          document.documentElement.style.setProperty(
            "--om-floating-height",
            `${bannerHeight}px`
          );
        }
      });
    });

    observer.observe(htmlElement, {
      attributes: true,
      attributeFilter: ["class"],
    });
  };

  onMounted(() => {
    if (process.client) {
      setupObserver();
    }
  });

  onUnmounted(() => {
    if (observer) {
      observer.disconnect();
    }
  });
}
