import type { PromoCodeInterface } from "@/cart/PromoCodeInterface";

export const handle = (promoCode: {
  id: number;
  code: string;
  cart_amount_minimum: number;
  cart_amount_maximum: number;
  aggregate_dollars_off_amount: number;
  description: string;
  description_checkout: string;
  show_banner: boolean;
  show_banner_checkout: boolean;
  regulations: any;
}): PromoCodeInterface => {
  return {
    id: promoCode.id,
    code: promoCode.code,
    cartAmountMinimum: promoCode.cart_amount_minimum,
    cartAmountMaximum: promoCode.cart_amount_maximum,
    aggregateDollarsOffAmount: promoCode.aggregate_dollars_off_amount,
    description: promoCode.description,
    descriptionCheckout: promoCode.description_checkout,
    showBanner: promoCode.show_banner,
    showBannerCheckout: promoCode.show_banner_checkout,
    tiers: promoCode?.regulations?.data || [],
  };
};
