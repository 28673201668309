import get from "lodash/get";
import size from "lodash/size";
import request from "../request";
import ValidationError from "../../models/ValidationError";
import {
  URL_LOGIN,
  URL_INITIAL_LOGIN,
  URL_RESET_PASSWORD,
  URL_REQUEST_RESET_PASSWORD,
  URL_USER,
  URL_VERIFY,
} from "../../constants/urls";
export const login = async (email, password) => {
  try {
    const response = await request.post(URL_LOGIN, { email, password });
    return response.data;
  } catch (e) {
    if (e.response.data && e.response.data.errors) {
      e.response.data.error =
        "The email and password combination is incorrect.";
    }
    return e.response.data;
  }
};
export const verify = async (code, verificationId) => {
  try {
    return await request.post(URL_VERIFY, { code, verificationId });
  } catch (e) {
    return e.response.data;
  }
};
export const loginInitially = async (token) => {
  await request.post(URL_INITIAL_LOGIN, { token });
};
export const logout = () => {
  return request.delete(URL_LOGIN);
};
export const requestResetPassword = async (email) => {
  try {
    await request.post(URL_REQUEST_RESET_PASSWORD, { email });
  } catch (e) {
    const errors = get(e.response, "data", {});
    throw size(errors) ? new ValidationError(errors) : e;
  }
};
export const resetPassword = async (email, password, token) => {
  try {
    const {
      data: { access_token: accessToken, expires_in: expiresIn },
    } = await request.post(URL_RESET_PASSWORD, {
      email,
      password,
      password_confirmation: password,
      token,
    });
    return { accessToken, expiresIn };
  } catch (e) {
    const errors = get(e.response, "data.errors", {});
    throw size(errors) ? new ValidationError(errors) : e;
  }
};

export const getUser = async () => {
  let response;

  try {
    response = await request.get(URL_USER);
  } catch (exception) {
    return null;
  }

  const user = response.data.data;

  return {
    id: user.id,
    email: user.email,
    referralId: user.referralId ? user.referralId : user.referral_id,
    profile: user.profile ? user.profile.data : null,
    subscription: user.subscription ? user.subscription.data : null,
    address: user.address ? user.address.data : null,
  };
};
