import DomainEvent from './DomainEvent'

export default class PickyEaterPopupActivated extends DomainEvent {
  get name() {
    return 'picky-eaters-popup-activated'
  }

  get url() {
    return '/api/marketing/events/picky-eater-popup-open'
  }
}
