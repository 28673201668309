import request from "../request";
import {
  URL_ABANDONED_CART_PLANS,
  URL_BESTSELLER_GROUPS,
  URL_CATEGORIES,
  URL_FILL_WITH_BESTSELLERS,
  URL_PLANS,
  URL_CATEGORY_QUANTITIES,
} from "@/constants/urls";
import { BEAM_NON_PROFIT } from "../../constants/urls";

export const getPlans = (params, abandonedCartId) => {
  if (!abandonedCartId) {
    return request.get(URL_PLANS, { params });
  }
  return request.get(
    URL_ABANDONED_CART_PLANS.replace("{{abandonedCartId}}", abandonedCartId),
    { params }
  );
};

export const fetchBestsellerGroups = (params = {}) => {
  return request.get(URL_BESTSELLER_GROUPS, { params });
};
export const fetchCategories = (params = {}) => {
  return request.get(URL_CATEGORIES, { params });
};
export const fillCartWithBestsellers = (
  abandonedCartId,
  bestsellerGroupId,
  sourceUrl
) => {
  return request.post(
    URL_FILL_WITH_BESTSELLERS.replace(
      "{{abandonedCartId}}",
      abandonedCartId
    ).replace("{{bestsellerGroupId}}", bestsellerGroupId),
    { source_url: sourceUrl }
  );
};
export const updateBeamNonProfit = (
  nonProfitId,
  nonProfitName,
  abandonedCartId
) => {
  return request.post(BEAM_NON_PROFIT, {
    non_profit_id: nonProfitId,
    non_profit_name: nonProfitName,
    abandoned_cart_id: abandonedCartId,
  });
};

export const getCategoryQuantities = () => {
  return request.get(URL_CATEGORY_QUANTITIES);
};
