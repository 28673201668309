import request from "@/services/request";
import { URL_MENU_ITEMS } from "@/constants/urls";
import { getMenuItemStock } from "@/services/api/products";
import each from "lodash/each";
import filter from "lodash/filter";
import get from "lodash/get";
import minBy from "lodash/minBy";
import sortBy from "lodash/sortBy";
import {
  makeProductImage,
  makeProductImages,
} from "@/services/api/abandonedCart/ProductImageApiFactory";
import { defineStore } from "pinia";
import type { MenuItemInterface } from "@/menus/item/MenuItemInterface";
import type { ProductInterface } from "@/menus/product/ProductInterface";

interface State {
  selectedMenuItem: MenuItemInterface | null;
  menuItems: MenuItemInterface[];
  productsList: ProductInterface[];
  stock: Record<string | number, ProductInterface[]>;
  productAboutToBeAddedToCart: {};
  pendingItemsPreset: null;
  showCaptureEmailPopup: boolean;
  filteredProductCount: number | null;
}

export const useMenusStore = defineStore("menus", {
  state: (): State => ({
    selectedMenuItem: null,
    menuItems: [],
    productsList: [] as ProductInterface[],
    stock: {},
    productAboutToBeAddedToCart: {},
    pendingItemsPreset: null,
    showCaptureEmailPopup: false,
    filteredProductCount: null,
  }),

  getters: {
    defaultMenuItem: (state) => state.menuItems?.[0] ?? null,
    stockSizes: (state) => {
      const sizes = {} as Record<string | number, number>;
      each(state.stock, (list, id) => {
        sizes[id] = list.length;
      });
      return sizes;
    },
    stockItemsByMenuItemId: (state) => (id: string | number) => {
      return get(state.stock, id, []);
    },
    leastExpensiveProductInCategory: (state) => (categoryID: number) => {
      return minBy(
        filter(state.stock[state?.selectedMenuItem?.id], (product) => {
          return product.category.id === categoryID;
        }),
        (product) => product.price.price
      );
    },
    isCaptureEmailPopupShown: ({ showCaptureEmailPopup }) =>
      showCaptureEmailPopup,
    pendingCartItem: ({ productAboutToBeAddedToCart }) =>
      productAboutToBeAddedToCart,
  },

  actions: {
    SET_SELECTED_MENU_ITEM(item: MenuItemInterface) {
      this.selectedMenuItem = item;
    },
    SET_MENU_ITEMS(menuItems: MenuItemInterface[]) {
      this.menuItems = sortBy(menuItems, (item) => -item.sorting_weight);
    },
    RESET_MENU_ITEM_STOCK(id: string) {
      this.stock[id] = [];
    },
    ADD_TO_MENU_ITEM_STOCK({ id, items }) {
      const preparedItems = items.map((item) => ({
        ...item,
        price: item.price.data,
        category: item.category.data,
        image: makeProductImage(item.image),
        images: makeProductImages(item.images.data ?? []),
      }));
      this.stock[id] = [
        ...this.stock[id].filter(
          (item) =>
            !preparedItems.some(
              (search: { sku: string }) => search.sku == item.sku
            )
        ),
        ...preparedItems,
      ];
      this.productsList = [
        ...this.productsList.filter(
          (item) =>
            !preparedItems.some(
              (search: { sku: string }) => search.sku == item.sku
            )
        ),
        ...preparedItems,
      ];
    },
    SET_PRODUCT_ABOUT_TO_BE_ADDED_TO_CART(item) {
      this.productAboutToBeAddedToCart = item;
    },
    SET_PENDING_ITEMS_PRESET(preset) {
      this.pendingItemsPreset = preset;
    },
    SET_FILTERED_PRODUCT_COUNT(count: number) {
      this.filteredProductCount = count;
    },
    SHOW_CAPTURE_EMAIL_POPUP(value: boolean) {
      this.showCaptureEmailPopup = value;
    },
    fetchMenuItemsStock({
      menuItem,
      indicators,
      tags,
      promoCode,
    }: {
      menuItem: MenuItemInterface;
      indicators: number[];
      tags: number[];
      promoCode: number | string;
    }) {
      const onboardingStore = useOnboardingStore();
      const authStore = useAuthStore();
      this.RESET_MENU_ITEM_STOCK(menuItem.id);
      const menusToFetch = [];
      // Each doesnt wait for the promises to be fulfilled
      const newMenu = new Promise<void>(async (resolve, reject) => {
        let hasMore = true;
        let page = 0;
        let abandonedCartId = null;

        if (!authStore.isLoggedIn) {
          abandonedCartId = onboardingStore.abandonedCartId;
        }

        while (hasMore) {
          const res = await getMenuItemStock({
            menuItem,
            indicators,
            tags,
            promoCode,
            abandonedCartId,
            page: page + 1,
            perPage: 9,
          });
          this.ADD_TO_MENU_ITEM_STOCK({
            id: menuItem.id,
            items: res.data.data,
          });
          let pagination = res.data.meta.pagination;
          hasMore = pagination.current_page < pagination.total_pages;
          page = pagination.current_page;
        }
        resolve();
      });

      menusToFetch.push(newMenu);

      return Promise.allSettled(menusToFetch);
    },
    async fetchMenuItems() {
      const onboardingStore = useOnboardingStore();
      const cartStore = useCartStore();

      return request
        .get(URL_MENU_ITEMS, {
          params: {
            promoCode: cartStore?.promoCode?.code,
            plan_id: onboardingStore.selectedPlanId,
            abandoned_cart: onboardingStore.abandonedCartId,
            include: [
              "categoryDisplayPrice",
              "primaryCategory",
              "primaryComposableCategory",
            ],
          },
        })
        .then((res) => {
          this.SET_MENU_ITEMS(res.data.data);
          return res.data.data;
        });
    },
    selectMenuItem(slug: string) {
      const found = this.menuItems.find((item) => item.slug === slug);
      if (found) {
        this.SET_SELECTED_MENU_ITEM(found);
      }
    },
  },
});
