import { handle as makePrice } from "@/services/api/abandonedCart/PriceFromApiFactory";
import type { ProductCategoryInterface } from "@/menus/product/ProductCategoryInterface";

export const handle = (category: {
  id: number;
  name: string;
  slug: string;
  description: string;
  price: { data: any };
}): ProductCategoryInterface => {
  return {
    id: category.id,
    name: category.name,
    slug: category.slug,
    description: category.description,
    price: makePrice(category.price.data),
  };
};
