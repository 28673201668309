import { GET_APP_SETTINGS, SET_APP_SETTINGS } from "@/stores/actions";
import { getAppSettings } from "@/services/api/appSettings";

export const useAppSettingsStore = defineStore("appSettings", {
  state: () => ({
    items: [],
  }),
  getters: {
    all: (state) => state.items,
    byGroup: (state) => {
      return (groupName) =>
        state.items.find((settings) => settings.group === groupName);
    },
    referralSystemDiscounts() {
      return this?.byGroup("referral_system_discounts")?.values;
    },
  },
  actions: {
    async [GET_APP_SETTINGS]() {
      const appSettings = await getAppSettings();
      if (appSettings) {
        this[SET_APP_SETTINGS](appSettings);
      }
    },
    [SET_APP_SETTINGS](settings) {
      this.items = [...settings];
    },
  },
});
