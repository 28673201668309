// Base implementation for auth store

import {defineStore} from "pinia";

import {
  getUser,
  login,
  loginInitially,
  logout,
  requestResetPassword,
  resetPassword,
  verify,
} from "@/services/api/auth";
import {clearImpersonate} from "@/services/storage";
import {UPDATE_AUTH_STATE} from "@/stores/actions";
import {track as trackUser} from "@/user/track/discovered";
import {URL_ACCOUNT} from "~/constants/urls";

interface AuthState {
  id: string | null;
  email: string | null;
  referralId: string | null;
  profile: any;
  subscription: any;
  address: any;
}

interface LoginParams {
  email: string;
  password: string;
}

interface VerificationParams {
  code: string;
  verificationId: string;
}

interface ResetPasswordParams {
  email: string;
  password: string;
  token: string;
}

interface UserProfile {
  name: string;
  age: number;
}

interface UserSubscription {
  status: string;
  expiresAt: string;
}

export interface UserAddress {
  id: number | null;
  line_1: string;
  line_2: string | null;
  state: string;
  city: string;
  zip_code: string;
}

interface User {
  id: string;
  email: string;
  referralId: string | null;
  profile: UserProfile | null;
  subscription: UserSubscription | null;
  address: UserAddress | null;
}
export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    id: null,
    email: null,
    referralId: null,
    profile: null,
    subscription: null,
    address: null,
  }),
  getters: {
    isLoggedIn: (state) => Boolean(state.id),
    userId: (state) => state.id,
    user: (state) => state,
  },
  actions: {
    async [VERIFY]({ code, verificationId }: VerificationParams) {
      const response = await verify(code, verificationId);

      response.authenticated && trackUser(this.email, true);
      this.CLEAR_IMPERSONATE();

      return response;
    },
    async [LOGIN]({ email, password }: LoginParams) {
      let responseData = {};

      try {
        responseData = await login(email, password);

        if (
          !responseData.authenticated &&
          responseData.nextAction === "verification"
        ) {
          const router = useRouter();
          router.push(
            `/onboarding/verification/?code=${responseData.verificationId}`
          );
          responseData.redirect = false;
        } else if (responseData.is_admin) {
          window.location.href = URL_ACCOUNT.replace('account.', 'admin.');

          return {
            redirect: false,
          };
        } else {
          trackUser(email, true);
          this[CLEAR_IMPERSONATE];
        }
      } catch (e) {
        throw e;
      }

      return responseData;
    },
    async [LOGIN_INITIALLY](token: string) {
      await loginInitially(token);
    },
    async [GET_USER]() {
      try {
        const user = await getUser();
        this[UPDATE_AUTH_STATE](user as User);
      } catch (e) {}
    },
    [REQUEST_RESET_PASSWORD](email: string) {
      return requestResetPassword(email);
    },
    async [RESET_PASSWORD]({ email, password, token }: ResetPasswordParams) {
      const cartStore = useCartStore();
      const onboardingStore = useOnboardingStore();

      await resetPassword(email, password, token);
      await this[GET_USER]();
      cartStore[CLEAR_CART]();
      onboardingStore[CLEAR_ABANDONED_CART]();
    },
    [CLEAR_IMPERSONATE]() {
      clearImpersonate();
    },
    async [LOGOUT]() {
      const onboardingStore = useOnboardingStore();

      trackUser(this.email, false);
      await logout();
      this[UPDATE_AUTH_STATE]({ id: null, email: null });
      onboardingStore[CREATE_ABANDONED_CART]();
    },
    [UPDATE_AUTH_STATE](stateUpdate: Partial<AuthState>) {
      this.$patch(stateUpdate);
    },
  },
});
