// Experimental middleware for routing

import { defineNuxtRouteMiddleware, navigateTo } from "nuxt/app";
import { ROUTE_CHECKOUT } from "~/constants/routes";
import { URL_ACCOUNT } from "~/constants/urls";

export default defineNuxtRouteMiddleware((to, from) => {
  const onboardingStore = useOnboardingStore();
  const cartStore = useCartStore();

  if (typeof window !== "undefined" && window?.om66228_58789 && from?.name) {
    window?.om66228_58789?.reset();
    document.documentElement.style.setProperty("padding-top", "0");
  }

  switch (true) {
    case to.path.includes("/onboarding/forgot-password"):
      if (onboardingStore.isForgotPasswordAvailable) {
        return;
      } else {
        return navigateTo(URL_ACCOUNT, {
          external: true,
        });
      }
    case to.path.includes(ROUTE_CHECKOUT):
      if (onboardingStore.isCheckoutStepAvailable) {
        return;
      } else {
        //TODO tmp for prod debug
        // return navigateTo("/menus");
      }
    case to.path.includes("/menus"):
      if (typeof window !== "undefined" && window.innerWidth >= 1024) {
        cartStore.OPEN_CART();
      }
      return;
    default:
      return;
  }
});
