import qs from "qs";
import trim from "lodash/trim";
import zip from "lodash/zip";
import omit from "lodash/omit";

const BUNDLE_SKUS_PARAM = "skus";
const BUNDLE_QUANTITIES_PARAM = "quantities";
const BUNDLE_CART_REPLACE_PARAM = "cart_replace";

export const getUrlReferralCode = () => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  return params["referral_id"];
};

export const getUrlRefereeEmail = () => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  return params["email"];
};

export const getUrlBundle = (params = null) => {
  let preset = {
    items: [],
    replace: false,
    getTotalQuantityAfterApplied(currentCartProductsCount) {
      return (
        this.items.reduce((count, item) => count + item.quantity, 0) +
        currentCartProductsCount
      );
    },
    requiresPlanUpgrade() {
      const onboardingStore = useOnboardingStore();
      const cartStore = useCartStore();

      return (
        this.getTotalQuantityAfterApplied(cartStore.cartProductsCount) >
        Number(onboardingStore?.selectedPlan?.threshold)
      );
    },
  };

  params = params
    ? params
    : qs.parse(window.location.search, { ignoreQueryPrefix: true });
  if (params.skus) {
    const skus = params[BUNDLE_SKUS_PARAM].split(",").map(trim);
    const quantities = params.quantities
      ? params[BUNDLE_QUANTITIES_PARAM].split(",").map(trim)
      : [];
    preset.items = zip(skus, quantities).map((item) => ({
      sku: item[0],
      quantity: item[1] ? parseInt(item[1], 10) : 1,
    }));
    return preset;
  }
  return null;
};

export const clearUrlBundle = () => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const clearParams = omit(
    params,
    BUNDLE_SKUS_PARAM,
    BUNDLE_QUANTITIES_PARAM,
    BUNDLE_CART_REPLACE_PARAM
  );
  const url = `${window.location.pathname}${qs.stringify(clearParams, {
    addQueryPrefix: true,
  })}`;
  window.history.replaceState(null, null, url);
};
