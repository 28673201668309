import get from "lodash/get";
import pickBy from "lodash/pickBy";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import { DOMAIN } from "@/constants/urls";
import request from "@/services/request";
import {
  URL_ABANDONED_CARTS,
  URL_ABANDONED_CART,
  URL_ABANDONED_CART_PROMO,
  URL_ABANDONED_CART_REFERRAL,
  URL_PROMO_CODE_BANNER,
  URL_ABANDONED_CART_FILTERS,
  URL_UPDATE_ABANDONED_CART_PLAN_ID,
} from "@/constants/urls";
import size from "lodash/size";
import ValidationError from "@/models/ValidationError";
import { allKeysToCamelCase } from "@/utils/helpers";
import { handle as parseCart } from "@/services/api/abandonedCart/CartFromApiFactory";
import { handle as parseAbandonedCart } from "@/services/api/abandonedCart/AbandonedCartFromApiFactory";
import type { AbandonedCartInterface } from "@/cart/AbandonedCartInterface";
import type { TrackingPageInterface } from "@/common/track/TrackingPageInterface";
import {track as trackUser} from "~/user/track/discovered";
import find from "lodash/find";

export const createAbandonedCart =
  async (): Promise<AbandonedCartInterface> => {
    try {
      const response = await request.post(URL_ABANDONED_CARTS);
      return parseAbandonedCart(response.data.data);
    } catch (exception: any) {
      if (!exception.response || !exception.response.data) {
        throw exception;
      }
      throw get(exception.response.data, "errors", {
        email: [
          "Sorry, something went wrong. An error has occurred on the server",
        ],
      });
    }
  };
export const getAbandonedCart = async (
  id: number
): Promise<AbandonedCartInterface> => {
  const url = URL_ABANDONED_CART.replace("{{cartId}}", id.toString());
  const response = await request.get(url, {
    params: {
      include: ["promoCode"],
    },
  });
  return parseAbandonedCart(response.data.data);
};
export const updateAbandonedCart = async (
  cartId: number,
  abandonedCart: AbandonedCartInterface,
  page: TrackingPageInterface
): Promise<AbandonedCartInterface> => {
  try {
    const url = URL_ABANDONED_CART.replace("{{cartId}}", cartId.toString());
    const toSend = pickBy(
      {
        email: abandonedCart.email,
        first_name: abandonedCart.firstName,
        last_name: abandonedCart.lastName,
        address_line_1: abandonedCart.addressLine1,
        address_line_2: abandonedCart.addressLine2,
        city: abandonedCart.city,
        state_id: abandonedCart.stateId,
        zip_code_id: abandonedCart.zipCodeId,
        delivery_instructions: abandonedCart.deliveryInstructions,
        phone: abandonedCart.phone,
        page: page.uri,
        location: page.location,
        receives_sms_updates: abandonedCart.receivesSmsUpdates,
        password: abandonedCart.password,
      },
      (value) => !isNull(value) && !isUndefined(value)
    );
    const response = await request.put(url, toSend);
    const pixels =response.data.meta?.pixels;
    if (pixels && pixels.length) {
      const leadPixel = find(pixels, { name: 'Lead' });
      trackUser(response.data.data.email, false, leadPixel.id);
    }

    return parseAbandonedCart(response.data.data);
  } catch (exception: any) {
    const errors = get(exception.response?.data, "errors", {});
    throw size(errors)
      ? new ValidationError(allKeysToCamelCase(errors))
      : exception;
  }
};
export const applyPromoCode = async (cartId: number, code: string) => {
  const url = URL_ABANDONED_CART_PROMO.replace("{{cartId}}", cartId.toString());
  const {
    data: {
      data: {
        cart: { data: responseCart },
      },
    },
  } = await request.post(url, { code: code });
  const cart = parseCart(responseCart);
  return { cart };
};
export const removePromoCode = async (cartId: Number) => {
  const url = URL_ABANDONED_CART_PROMO.replace("{{cartId}}", cartId.toString());
  const {
    data: {
      data: {
        cart: { data: responseCart },
      },
    },
  } = await request.delete(url);

  const cart = parseCart(responseCart);

  return {
    cart,
    promoCode: {
      code: get(cart.promoCode, "code", ""),
      description: get(cart.promoCode, "description", null),
    },
  };
};
export const applyReferralCode = async (
  cartId: number,
  code: string,
  refereeEmail: string
) => {
  const url = URL_ABANDONED_CART_REFERRAL.replace(
    "{{cartId}}",
    cartId.toString()
  );

  const {
    data: {
      data: {
        referrer,
        referee,
        cart: { data: responseCart },
        referral_code: referralCode,
        referral_code_status: referralCodeStatus,
        on_referral_redemption_limit: onReferralRedemptionLimit,
      },
    },
  } = await request.post(url, { referral_code: code, refereeEmail });

  return {
    cart: parseCart(responseCart),
    referralCode,
    referralCodeStatus,
    referrer: {
      firstName: get(referrer, "first_name", null),
      lastName: get(referrer, "last_name", null),
    },
    referee: {
      firstName: get(referee?.data, "first_name", null),
      lastName: get(referee?.data, "last_name", null),
      email: get(referee?.data, "email", null),
    },
  };
};
export const updateAbandonedCartFilters = async (
  cartId: number,
  { indicators, tags }: { indicators: Array<number>; tags: Array<number> }
) => {
  const url = URL_ABANDONED_CART_FILTERS.replace(
    "{{cartId}}",
    cartId.toString()
  );
  return request.post(url, { indicators, tags });
};
export async function updatePlan(
  abandonedCartId: number,
  planId: number,
  sourceUrl: string
) {
  const url = URL_UPDATE_ABANDONED_CART_PLAN_ID.replace(
    "{{abandonedCartId}}",
    abandonedCartId.toString()
  );
  const response = await request.post(url, {
    plan_id: planId,
    source_url: sourceUrl,
  });
  return {
    abandonedCart: parseAbandonedCart(response.data.data),
    eventId: response.data.meta.event_id,
  };
}
