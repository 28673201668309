import get from "lodash/get";
import { URL_MENUS_PAGE } from "@/constants/urls";

const RESPONSE_DATA_ERROR_CODE = 10002;
const RESPONSE_STATUS_ERROR_CODE = 400;

export default function (error: any) {
  if (
    get(error, "response.status") === RESPONSE_STATUS_ERROR_CODE &&
    get(error, "response.data.code") === RESPONSE_DATA_ERROR_CODE
  ) {
    window.location.href = URL_MENUS_PAGE;
  }

  return Promise.reject(error);
}
