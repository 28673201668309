// auth
// auth
export const LOGIN = "LOGIN";
export const VERIFY = "VERIFY";
export const LOGIN_INITIALLY = "LOGIN_INITIALLY";
export const LOGOUT = "LOGOUT";
export const GET_USER = "GET_USER";
export const REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CLEAR_IMPERSONATE = "CLEAR_IMPERSONATE";

// onboarding
export const IDENTIFY_TRACKING_USER = "IDENTIFY_TRACKING_USER";

export const GET_ABANDONED_CART = "GET_ABANDONED_CART";
export const RESTORE_ABANDONED_CART = "RESTORE_ABANDONED_CART";
export const CREATE_ABANDONED_CART = "CREATE_ABANDONED_CART";
export const UPDATE_ABANDONED_CART = "UPDATE_ABANDONED_CART";
export const CLEAR_ABANDONED_CART = "CLEAR_ABANDONED_CART";

// cart
export const SET_CART_ID = "SET_CART_ID";
export const TOGGLE_CART = "TOGGLE_CART";
export const HIDE_CART = "HIDE_CART";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_BUNDLE = "ADD_BUNDLE";
export const SUBTRACT_PRODUCT = "SUBTRACT_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const EMPTY_CART = "EMPTY_CART";
export const CLEAR_CART = "CLEAR_CART";
export const APPLY_PROMO = "APPLY_PROMO";
export const REMOVE_PROMO = "REMOVE_PROMO";
export const APPLY_REFERRAL_CODE = "APPLY_REFERRAL_CODE";
export const SHOW_CART_GUIDE = "SHOW_CART_GUIDE";
export const HIDE_CART_GUIDE = "HIDE_CART_GUIDE";

// orders
export const GET_ORDERS = "GET_ORDERS";
export const GET_PROMO_CODE = "GET_PROMO_CODE";

// gift cards
export const GET_GIFT_CARD_PROMOTIONS = "GET_GIFT_CARD_PROMOTIONS";
export const GET_GIFT_CARD_SUGGESTIONS = "GET_GIFT_CARD_SUGGESTIONS";
export const UPDATE_GIFT_CARD_AMOUNT = "UPDATE_GIFT_CARD_AMOUNT";
export const PURCHASE_GIFT_CARD = "PURCHASE_GIFT_CARD";

// PAYMENTS
export const CREATE_PAYMENT_INTENT = "CREATE_PAYMENT_INTENT";

// APP SETTINGS
export const GET_APP_SETTINGS = "GET_APP_SETTINGS";

// UPSELLS
export const GET_UPSELL = "GET_UPSELL";
export const ADD_UPSELL_ITEM = "ADD_UPSELL_ITEM";
export const REMOVE_UPSELL_ITEM = "REMOVE_UPSELL_ITEM";

export const UPDATE_AUTH_STATE = "UPDATE_AUTH_STATE";
export const UPDATE_CART_STATE = "UPDATE_CART_STATE";
export const UPDATE_ONBOARDING_STATE = "UPDATE_ONBOARDING_STATE";
export const UPDATE_CHECKOUT_STATE = "UPDATE_CHECKOUT_STATE";
export const UPDATE_GIFT_CARD_STATE = "UPDATE_GIFT_CARD_STATE";

export const SET_CART = "SET_CART";
export const SET_ORDERS = "SET_ORDERS";
export const SET_PROMO_CODE = "SET_PROMO_CODE";
export const SET_PROMO_CODE_BANNER = "SET_PROMO_CODE_BANNER";
export const SET_GIFT_CARD_PROMOTIONS = "SET_GIFT_CARD_PROMOTIONS";
export const SET_GIFT_CARD_SUGGESTIONS = "SET_GIFT_CARD_SUGGESTIONS";
export const SET_GIFT_CARD_MIN_AMOUNT = "SET_GIFT_CARD_MIN_AMOUNT";
export const SET_INDICATORS = "SET_INDICATORS";
export const SET_TAGS = "SET_TAGS";
export const SET_MENU_ITEMS = "SET_MENU_ITEMS";
export const SET_SELECTED_MENU_ITEM = "SET_SELECTED_MENU_ITEM";

export const RESET_MENU_ITEM_STOCK = "RESET_MENU_ITEM_STOCK";
export const RESET_MENU_ITEM_STOCK_IF_NO_PRODUCTS =
  "RESET_MENU_ITEM_STOCK_IF_NO_PRODUCTS";
export const ADD_TO_MENU_ITEM_STOCK = "ADD_TO_MENU_ITEM_STOCK";

export const SET_APP_SETTINGS = "SET_APP_SETTINGS";

export const SET_UPSELL_ITEMS = "SET_UPSELL_ITEMS";
export const SET_UPSELL_CART = "SET_UPSELL_CART";
export const SET_UPSELL_BUSY_STATUS = "SET_UPSELL_BUSY_STATUS";
