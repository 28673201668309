export const useViewportHeight = () => {
  const setViewportProperty = () => {
    const winH = window.innerHeight || document.documentElement.clientHeight;
    document.documentElement.style.setProperty("--vh", `${winH}px`);
  };

  onMounted(() => {
    setViewportProperty();

    window.addEventListener("resize", setViewportProperty);
    window.addEventListener("orientationchange", setViewportProperty);
    window.addEventListener("scroll", setViewportProperty);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", setViewportProperty);
    window.removeEventListener("orientationchange", setViewportProperty);
    window.removeEventListener("scroll", setViewportProperty);
  });
};
