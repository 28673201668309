const DURATION_TIME_MS = 4000;
export default class Flash {
  constructor(message) {
    this.message = message;
  }

  success(duration = DURATION_TIME_MS, position = null) {
    this.dispatch("success", duration, position);
  }

  error(duration = DURATION_TIME_MS, position = null) {
    this.dispatch("error", duration, position);
  }

  dispatch(type, duration = DURATION_TIME_MS, position = null) {
    let event = new CustomEvent("flashMessage", {
      detail: { type, message: this.message, duration, position },
      bubbles: true,
    });
    document.dispatchEvent(event);
  }
}
